//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProductNavigationList from './ProductNavigationList.vue'
import ProductMicroInfoMiniature from '~/components/modules/Miniatures/ProductMicroInfoMiniature.vue'
import {
  IS_MOBILE,
  IS_LARGE_MOBILE,
  IS_TABLET,
  IS_LARGE_TABLET,
  IS_LAPTOP,
  IS_DESKTOP,
} from '~/store/device/type.device'
export default {
  name: 'ProductNavigation',
  components: { ProductMicroInfoMiniature, ProductNavigationList },
  props: {
    items: {
      type: Array,
      default: () => [],
      require: true,
    },
    product: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fixed: false,
      selected: {},
      active: false,
    }
  },

  computed: {
    isMobile() {
      return this.$store.getters[`device/${IS_MOBILE}`]
    },
    isLargeMobile() {
      return this.$store.getters[`device/${IS_LARGE_MOBILE}`]
    },
    isTablet() {
      return this.$store.getters[`device/${IS_TABLET}`]
    },
    isLargeTablet() {
      return this.$store.getters[`device/${IS_LARGE_TABLET}`]
    },
    isLaptop() {
      return this.$store.getters[`device/${IS_LAPTOP}`]
    },
    isDesktop() {
      return this.$store.getters[`device/${IS_DESKTOP}`]
    },
    isProduct() {
      return !!Object.keys(this.product).length
    },
    isShow() {
      if (this.isDesktop || this.isLaptop) return true
      return this.active
    },
    topOffset() {
      return 0
    },
  },
  watch: {
    items() {
      this.selected = this.items[0]
    },
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll)
    setTimeout(() => {
      if(this.$route.query.toReviews) {
        let reviewsItem = document.querySelector('.product-page .navigation .navigation--item:last-child')
        this.selected.name = reviewsItem.textContent.trim();
      }
    }, 0)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll() {
      const navigation = this.$refs.navigation
      if (!navigation) return
      const navTop = navigation.getBoundingClientRect().top
      if (navTop < this.topOffset) this.fixed = true
      else this.fixed = false
    },
    handleNavigation(prop, value) {
      this.$emit('click', prop)
      this.selected = value
    },
  },
}
